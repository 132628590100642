import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ServerWebFetch } from './includes/include.js';

import "./css/index.css";

//must load firs
import Header from './header.js';

const MainView = React.lazy(()=>import("./main.js"));
const Profile = React.lazy(()=>import("./profile"));
const Login = React.lazy(()=>import("./login.js"));
//import MainView from "./main/main.js";

const search=new URLSearchParams(window.location.search);

const pathsWithNoHeaders=["/login"];

var session="noFetched";

ServerWebFetch({
  action:"WEB_GET_SESSION"
}).then((res)=>{
  session=res?.session;

  window.dispatchEvent(new CustomEvent("session",{
    detail:{
      session:((typeof res?.session == "object")?res.session:false)
    }
  }));
});

createRoot(document.getElementById('root')).render(
  <Router>
    {
      //dont add header in paths in pathsWithNoHeaders
      !(pathsWithNoHeaders.find((v)=>v==window.location.pathname))&&<Header/>
    }
    <Suspense fallback={<h1>Loading...</h1>}>
      <Routes>
        <Route path="/"         element={<MainView/>} />
        <Route path="/profile"  element={<Profile user={search.get("u")}/>} />
        <Route path="/login"    element={<Login user={search.get("u")}/>} />
      </Routes>
    </Suspense>
  </Router>
)

