//import { useState } from "react";
import { useEffect, useState } from "react";
import "./css/table.css";

export function Table(props){
    
    let title=<h3>Loading...</h3>;
    let content=<div></div>;

    let pagElement=null;
    
    const [rows,setRows]=useState(props.rows||[]);
    
    const actions={
        deleteRow:(rowIndex)=>{
            setRows(rows.filter((val,ind)=>ind!=rowIndex));
        }
    }
    
    if(!props.loading && typeof props.message!="string"){
        
        if(props.colnames?.constructor?.name !== "Array")throw new Error("Table.colnames is not array");
        if(props.rows?.constructor?.name !== "Array")throw new Error("Table.rows is not array");
        if(!props.title)throw new Error("Table.title is not setted");

        const changePage=(p,psize,count)=>{
            setRows(props.rows.slice(p*psize,(p+1)*psize));
            if(typeof props.pagination.onChangePage == "function")
                props.pagination.onChangePage(p,psize,count);
            if(typeof props.pagination.onChangePageReachEnd == "function" && p==(count-1))
                props.pagination.onChangePageReachEnd(p,psize,count);
        }

        if(typeof props.pagination == "object" && props.pagination.pageSize<props.rows.length){
            let page=props.pagination.page||0;
            let pageSize=props.pagination.pageSize||rows.length;
            const pagesCount=Math.ceil(pageSize>0?(props.rows.length/pageSize):0);

            if(pagesCount>0 && pageSize>0){
                while(page<0){
                    page+=pagesCount;
                }

                while(page>=pagesCount){
                    page-=pagesCount;
                }
            }

            pagElement=<PaginationSection 
                page={page} 
                pagesCount={pagesCount} 
                newPageClicked={(p)=>changePage(p,pageSize,pagesCount)}
            />;
        }
        
        let colnames=props.colnames.slice();
        
        if(props.colAdd?.name){
            if(props.colAdd.name.constructor?.name === "Array"){
                colnames.concat(props.colAdd.name);
            }
            else if(typeof props.colAdd.name == "function")
                colnames.push(props.colAdd.name());
            else
                colnames.push(props.colAdd.name);
        }

        colnames=colnames.map((colname,ind)=>{
            return (
                <th key={ind}>{colname}</th>
            );
        });
        
        title=<h3>{props.title}</h3>;

        content=(
        <table className="table table-striped table-bordered table-hover table-sm">
            <thead>
                <tr>
                    {colnames}
                </tr>
            </thead>
            <TableRows rows={rows} colAdd={props.colAdd} actions={actions}/>
        </table>);
    }
    
    if(typeof props.message == "string"){
        title=<h3>{props.message}</h3>;
    }
    
    return(
        <div>
            <div className="table-container">
                <div className="table-responsive table-borderless table-score">
                    {title}
                    {content}
                    {pagElement}
                </div>
            </div>
        </div>
    );
}

function TableRows({rows, colAdd, actions}){
    return <tbody>{rows.map((row,index)=>{
        row=row.slice();

        if(row.constructor?.name !== "Array")
            throw new Error("Table.rows["+index+"] is not array");

        //adding a column, but making it able to accept many ways to do it
        if(colAdd?.field){
            if(colAdd.field.constructor?.name === "Array")
                row.concat(colAdd.fields||colAdd.field);
            else if(typeof colAdd.field == "function"){
                let returned=colAdd.field(row.slice(),index,actions);
                if(returned.constructor?.name === "Array")
                    row.concat(returned);
                else
                    row.push(returned);
            }
            else
                row.push(colAdd.field);
        }

        //tr row return
        return (<tr key={index}>
            {row.map((field,index)=>{
                const lineBreak=(text)=>{
                    if( (typeof text === "string") && text.indexOf("\n")!=-1)
                        return <ul>
                            {text.split("\n").map((t,i)=>{
                                return <li key={i}>
                                    {t}
                                </li>;
                            })}
                        </ul>;
                    
                    return text;
                }

                //if received a field for redirecting
                if(field.href && field.content)
                    field=<a className="table-a" href={field.href}>{lineBreak(field.content)}</a>;
                
                return (<td key={index}>{lineBreak(field)}</td>);
            })}
        </tr>);
    })}</tbody>;
}

export function PaginationSection({pagesCount,page,newPageClicked}){
    
    const [pageState,setPageState] = useState(page);

    useEffect(()=>{
        /*debug//*/console.log({pagesCount,page,newPageClicked});
        if(typeof newPageClicked == "function")
        newPageClicked(page,pagesCount);
    },[]);
    
    const pageClicked=(number)=>{
        ///*debug//*/console.log({pagesCount,page,newPageClicked});
        if(typeof newPageClicked == "function")
        newPageClicked(number,pagesCount);

        setPageState(number);
    }

    return <CalculatePageButtons page2Calc={pageState} clickcb={pageClicked} pagesCount={pagesCount}/>;
}

function CalculatePageButtons({page2Calc,clickcb,pagesCount}){
    let pageChangeButtons=[];
    
    pageChangeButtons.push(
        page2Calc>0?
        <p key={"first"} onClick={()=>clickcb(0)}>{"<<"}</p>:
        <p key={"first"}>{" "}</p>
    );
    
    pageChangeButtons.push(
        page2Calc>0?
        <p key={"prev"} onClick={()=>clickcb(page2Calc-1)}>{"<"}</p>:
        <p key={"prev"}>{" "}</p>
    );
    
    pageChangeButtons.push(
        page2Calc-4>=0?
        <p key={"db"}>{"..."}</p>:
        <p key={"db"}>{" "}</p>
    );
    
    for(let i=-3;i<=3;i++){
        pageChangeButtons.push(
            page2Calc+i>=0 && page2Calc+i<pagesCount?
            <p key={page2Calc+i} className={(i==0&&"current")||""} onClick={()=>{if(i!=0)clickcb(page2Calc+i);}}>{page2Calc+i}</p>:
            <p key={page2Calc+i}>{" "}</p>
        );
        
    }
    
    pageChangeButtons.push(
        page2Calc+4<pagesCount?
        <p key={"dn"}>{"..."}</p>:
        <p key={"dn"}>{" "}</p>
    );
    
    pageChangeButtons.push(
        page2Calc<(pagesCount-1)?
        <p key={"next"} onClick={()=>clickcb(page2Calc+1)} >{">"}</p>:
        <p key={"next"}>{" "}</p>
    );
    pageChangeButtons.push(
        page2Calc<(pagesCount-1)?
        <p key={"last"} onClick={()=>clickcb(pagesCount-1)} >{">>"}</p>:
        <p key={"last"}>{" "}</p>
    );
        
    return <div className="table-pagination">
        {pageChangeButtons}
    </div>;
}