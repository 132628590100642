import { useEffect, useState } from "react";
import { Table } from "../props/table.js";
import config from "../config/index.js";

const webapi="webapi";
const api="api";

export function ServerWebFetch(body){
    return new Promise((resolve,reject)=>{
        fetch(config.backendUrl+webapi+"/main.php", {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: "include",
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res =>{
            if(!res.body){
                reject("received empty answer");
                throw new Error("received empty answer");
                return;
            }
            return res.json();
        })
        .catch(error => console.error('Fetch error:', error))
        .then((response)=>{
            if(!response){
                reject("empty json answer");
                throw new Error("empty json answer");
                return;
            }
            resolve(response);
        });
    });
}

export function ServerWebSendFile(file,options){

    let toSend=new FormData();
    toSend.append("file",file);
    toSend.append("options",JSON.stringify(options));
    
    return new Promise((resolve,reject)=>{
        
        fetch(config.backendUrl+webapi+"/files.php",{
            method: 'POST',
                body: toSend,
                credentials: "include"
            }
        )
        .then(res =>{
            if(!res.body){
                reject("Sending file error: received empty answer");
                throw new Error("Sending file error: received empty answer");
                return;
            }
            return res.json();
        })
        .catch(error => console.error('Sending file error:', error))
        .then((response)=>{
            if(!response){
                reject("Sending file error: empty json answer");
                throw new Error("Sending file error: empty json answer");
                return;
            }
            resolve(response);
        });
    });
}

export function ServerSystemAPIFetch(body){
    return new Promise((resolve,reject)=>{
        fetch(config.backendUrl+api+"/main.php", {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: "include"
        }).then(res =>{
            if(!res.body){
                reject("received empty answer");
                throw new Error("received empty answer");
                return;
            }
            return res.json();
        })
        .catch(error => console.error('Fetch error:', error))
        .then((response)=>{
            /*debug*///console.log(response);
            if(!response){
                reject("empty json answer");
                throw new Error("empty json answer");
                return;
            }
            resolve(response);
        });
    });
}

export function FetchReportTables(
    EncloserCallback=((table,key)=>table),
    userId=-1,
    details={table_size:20},
    pagination=false,
    tables="GET_MAIN_REPORTS_TABLES"
){
    return new Promise((resolve,reject)=>{

        if(typeof EncloserCallback != "function")
            return;
    
        ServerWebFetch({
            action:"WEB_TABLES",
            sub_a:tables,
            user: userId,
            details
        })
        .catch(error=>reject(error))
        .then(response =>{
    
            if(response.tables?.constructor?.name != "Array" ||
                response.tables?.length == 0
            ){
                resolve(<Table message="No activity"/>);
                return;
            }
    
            /*debug*///console.log(response);
            resolve(response.tables.map((table,ind)=>{
                return EncloserCallback(
                    <Table 
                        key={ind} 
                        title={table.title} 
                        colnames={table.colnames} 
                        rows={table.rows}
                        pagination={pagination&&{
                            pageSize:10
                        }}
                    />,
                    ind
                );
            }));
        });
    });
}

export function FetchDevicesTables({EncloserCallback,userId=-1,details=null,putActions=null}){

    const [devicesTables,setDevicesTables] = useState(<div className="col-xs-12 col-lg-12"><Table loading="1" /></div>);
    
    useEffect(()=>{
        if(typeof EncloserCallback != "function")
            EncloserCallback=(t,k)=><div key={k} className="col-xs-12 col-lg-12">{t}</div>;
    
        ServerWebFetch({
            action:"GET_DEVICES_TABLES",
            user: userId,
            details
        })
        // .catch(err=>reject(err))
        .then(response =>{
            /*debug*///console.log(response);
    
            if(response.tables?.constructor?.name != "Array" ||
                response.tables?.length == 0
            ){
                setDevicesTables(<Table message="No devices"/>);
                return;
            }
    
            let actuallyPutActions=null;
            if(putActions){
                //for now, lets keep it as function
                if(typeof putActions == "function"){
                    actuallyPutActions={
                        name:"Action",
                        field:(row,rowIndex,actions)=><ul>
                            <li className="table-a" onClick={(e)=>putActions(e,"pickup",row,rowIndex,actions)}>
                                Pick up 
                            </li>
                        </ul>
                    };
                }
            }
    
            setDevicesTables(response.tables.map((table,ind)=>{
    
                return EncloserCallback(
                    <Table 
                        key={ind} 
                        title={table.title} 
                        colnames={table.colnames} 
                        rows={table.rows}
                        colAdd={actuallyPutActions}
                        pagination={{
                            pageSize:10
                        }}
                    />,
                    ind
                );
            }));
        });
    },[]);


    return devicesTables;
}