import { useEffect, useState } from "react";
import "./css/header.css";

function Header(){

    const [options,setOptions] = useState([
        <NavbarOption key="home" href="/">
            Home
        </NavbarOption>
    ]);

    useEffect(()=>{
        window.addEventListener("session",(e)=>{
            if(e.detail.session){
                setOptions([
                    ...options,
                    <NavbarOption key="prof" href={`/profile?u=${e.detail?.session?.id}`}>
                        My profile
                    </NavbarOption>
                ]);
            }
        });
    },[]);

    return (
    <div className="shasiya_header">
        <img src="assets/img/Shasiyamilk.png" style={{width: "35%",alignSelf:"center"}}/>
        <nav className="navbar navbar-light navbar-expand-md" style={{fontFamily:"Aclonica, sans-serif",fontSize:"19px"}}>
                
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
            {options}
        </ul>
    </div>
    </nav>
</div>
    );
}


function NavbarOption(props){
    return(
        <li className={"nav-item"+((props.href==window.location.pathname+window.location.search)?" active":"")}>
            <a className="nav-link" href={props.href}>{props.children}</a>
        </li>
    );
}


export default Header;



/*

//Navbar brand
<a className="navbar-brand" href="#">Navbar</a>

//Search form, goes inside <div id="navbarSupportedContent">
<form className="form-inline my-2 my-lg-0">
    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
    <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
</form>


<li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                Dropdown
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">Action</a>
                <a className="dropdown-item" href="#">Another action</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">Something else here</a>
            </div>
        </li>
        <li className="nav-item">
            <a className="nav-link disabled">Disabled</a>
        </li>
        <div class="container-fluid"><button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1"><span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navcol-1">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#">First Item</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Second Item</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Remover clase "col" de los table container</a></li>
                </ul>
            </div>
        </div>
    </nav>
*/